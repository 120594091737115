<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'contratantes.cpts',
                  id: id,
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              (contractor ? contractor.name + ' | ' : '') +
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${table_data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!table_data.length" dense>
        <v-col cols="12" sm="12" md="9">
          <v-file-input
            v-model="document_file_0"
            accept=".xlsx"
            label="Archivo"
            dense
          />
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-btn block small color="primary" @click.prevent="getData()">
            Cargar
            <v-icon right> mdi-check </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col cols="12" xs="12" md="9">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-btn block small color="default" @click="table_data = []">
            Cargar otro archivo
            <v-icon right> mdi-file </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="table_data"
            :search="table_search"
            :headers="table_headers"
            :loading="loading"
            :items-per-page="-1"
            :item-class="tableRowBg"
            dense
          >
            <template v-slot:item.xls_description="{ item }">
              {{ item.xls_description }}

              <v-tooltip
                v-if="!item.new && item.xls_description != item.description"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="warning">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span v-text="item.description" />
              </v-tooltip>
            </template>
            <template v-slot:item.xls_amount="{ item }">
              {{ numberFormat(item.xls_amount) }}

              <v-tooltip
                v-if="!item.new && item.xls_amount != item.amount"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="warning">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span v-text="numberFormat(item.amount)" />
              </v-tooltip>
            </template>
            <template v-slot:item.xls_system="{ item }">
              {{ item.xls_system }}{{ item.new_system ? "*" : "" }}

              <v-tooltip
                v-if="
                  !item.new &&
                  (item.xls_system_id != item.cpt_system_id || item.new_system)
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="warning">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span
                  v-text="item.cpt_system ? item.cpt_system : 'NO REGISTRADO'"
                />
              </v-tooltip>
            </template>
            <template v-slot:item.xls_specialty="{ item }">
              {{ item.xls_specialty }}{{ item.new_specialty ? "*" : "" }}

              <v-tooltip
                v-if="
                  !item.new &&
                  (item.xls_specialty_id != item.cpt_specialty_id ||
                    item.new_specialty)
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="warning">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span
                  v-text="
                    item.cpt_specialty ? item.cpt_specialty : 'NO REGISTRADO'
                  "
                />
              </v-tooltip>
            </template>
            <template v-slot:item.id="{ item }">
              <v-icon small :color="item.id ? '' : 'success'">
                mdi-{{ item.id ? "close" : "check" }}
              </v-icon>
            </template>
            <template v-slot:item.save="{ item }">
              <v-simple-checkbox v-model="item.save" :ripple="false" />
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn small color="success" @click.prevent="saveData">
            Guardar información
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  toFormData,
  msgConfirm,
  msgAlert,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      id: this.$route.params.id,
      login: this.$store.getters.getLogin,
      loading: false,
      document_file_0: null,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: false, value: "index" },
        { text: "Código", filterable: true, value: "code" },
        { text: "Descripción", filterable: true, value: "xls_description" },
        { text: "Monto", filterable: true, value: "xls_amount" },
        {
          text: "Sistema",
          filterable: true,
          value: "xls_system",
        },
        {
          text: "Especialidad",
          filterable: true,
          value: "xls_specialty",
        },
        {
          text: "Nuevo en contratante",
          filterable: true,
          value: "id",
        },
        {
          text: "Actualizar BD",
          filterable: true,
          value: "save",
        },
      ],
      contractor: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    tableRowBg: function (item) {
      if (item.new) return "green lighten-4";
      return "";
    },
    getData() {
      if (this.document_file_0 != null) {
        this.loading = true;
        this.table_data = [];

        Axios.post(
          URL_API + "/contractors/cpts/migration",
          toFormData({
            contractor_id: this.id,
            document_file_0: this.document_file_0,
          }),
          headersToken(this.login.token)
        )
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.length > 0) {
                this.table_data = res.data.data;
              } else {
                this.$swal.fire(
                  msgAlert(
                    "warning",
                    "No se encontraron registros en el archivo"
                  )
                );
              }
            } else {
              console.log(res.data.message);
              this.$swal.fire(msgAlert("error", "Formato de archivo invalido"));
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$swal.fire(msgAlert("error", "Error en lectura de archivo"));
            this.loading = false;
          });
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona un archivo"));
      }
    },
    saveData() {
      let update = false;
      for (const item of this.table_data) {
        if (item.save) {
          update = true;
          break;
        }
      }

      if (update) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/contractors/cpts/migration/save",
                {
                  contractor_id: this.id,
                  contractor_cpts: this.table_data,
                },
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.getData();
                } else {
                  console.log(res.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert("warning", "Se debe de seleccionar al menos un registro")
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/contractors/" + this.id,
      headersToken(this.login.token)
    ).then((response) => {
      this.contractor = response.data.data;
    });
  },
};
</script>