var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name: 'contratantes.cpts',
                id: _vm.id,
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            (_vm.contractor ? _vm.contractor.name + ' | ' : '') +
            (_vm.$route.meta.title) + " " + (_vm.loading
                ? ''
                : _vm.table_data.length > 0
                ? ("(" + (_vm.table_data.length) + ")")
                : '')
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})])],1)],1)],1),_c('v-card-text',[(!_vm.table_data.length)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-file-input',{attrs:{"accept":".xlsx","label":"Archivo","dense":""},model:{value:(_vm.document_file_0),callback:function ($$v) {_vm.document_file_0=$$v},expression:"document_file_0"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-btn',{attrs:{"block":"","small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.getData()}}},[_vm._v(" Cargar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-check ")])],1)],1)],1):_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.table_search),callback:function ($$v) {_vm.table_search=$$v},expression:"table_search"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-btn',{attrs:{"block":"","small":"","color":"default"},on:{"click":function($event){_vm.table_data = []}}},[_vm._v(" Cargar otro archivo "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-file ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.table_data,"search":_vm.table_search,"headers":_vm.table_headers,"loading":_vm.loading,"items-per-page":-1,"item-class":_vm.tableRowBg,"dense":""},scopedSlots:_vm._u([{key:"item.xls_description",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.xls_description)+" "),(!item.new && item.xls_description != item.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"warning"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.description)}})]):_vm._e()]}},{key:"item.xls_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.xls_amount))+" "),(!item.new && item.xls_amount != item.amount)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"warning"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.numberFormat(item.amount))}})]):_vm._e()]}},{key:"item.xls_system",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.xls_system)+_vm._s(item.new_system ? "*" : "")+" "),(
                !item.new &&
                (item.xls_system_id != item.cpt_system_id || item.new_system)
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"warning"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.cpt_system ? item.cpt_system : 'NO REGISTRADO')}})]):_vm._e()]}},{key:"item.xls_specialty",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.xls_specialty)+_vm._s(item.new_specialty ? "*" : "")+" "),(
                !item.new &&
                (item.xls_specialty_id != item.cpt_specialty_id ||
                  item.new_specialty)
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"warning"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(
                  item.cpt_specialty ? item.cpt_specialty : 'NO REGISTRADO'
                )}})]):_vm._e()]}},{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.id ? '' : 'success'}},[_vm._v(" mdi-"+_vm._s(item.id ? "close" : "check")+" ")])]}},{key:"item.save",fn:function(ref){
                var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},model:{value:(item.save),callback:function ($$v) {_vm.$set(item, "save", $$v)},expression:"item.save"}})]}}])})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_vm._v(" Guardar información ")])],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }